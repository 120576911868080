import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { MeetingBookingQueryParams } from '@features/meeting-booking/constants/meeting-booking-query-params.constant';
import { MeetingBookingTab } from '@features/meeting-booking/enums/meeting-booking-tab.enum';
import { MeetingNotificationType } from '../enums/meeting-notification-type.enum';
import { AppNotification } from '../interfaces/notification.interface';

const navigateToMeetingAction = AppRoutingActions.goToAppPage({
    urlSegments: [AppPageRoutes.user, AppUserPageRoutes.meetings],
    extras: { queryParams: { [MeetingBookingQueryParams.date]: MeetingBookingTab.Upcoming } }
});

const meetingNotificationDefaults: Partial<AppNotification> = {
    action: navigateToMeetingAction,
    actionAriaLabel: 'MEETING_NOTIFICATION_ARIA_LABEL'
};

export const MeetingNotificationMap: Record<MeetingNotificationType, Partial<AppNotification>> = {
    [MeetingNotificationType.inviteSent]: {
        title: 'MEETING_NOTIFICATION_INVITE_SENT_TITLE',
        message: 'MEETING_NOTIFICATION_INVITE_SENT_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.inviteAccepted]: {
        title: 'MEETING_NOTIFICATION_INVITE_ACCEPTED_TITLE',
        message: 'MEETING_NOTIFICATION_INVITE_ACCEPTED_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.inviteDeclined]: {
        title: 'MEETING_NOTIFICATION_INVITE_DECLINED_TITLE',
        message: 'MEETING_NOTIFICATION_INVITE_DECLINED_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.meetingCancelled]: {
        title: 'MEETING_NOTIFICATION_CANCELLED_TITLE',
        message: 'MEETING_NOTIFICATION_CANCELLED_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.meetingUpdated]: {
        title: 'MEETING_NOTIFICATION_UPDATE_ACCEPTED_TITLE',
        message: 'MEETING_NOTIFICATION_UPDATE_ACCEPTED_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.meetingPending]: {
        title: 'MEETING_NOTIFICATION_PENDING_TITLE',
        message: 'MEETING_NOTIFICATION_PENDING_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.meetingStarted]: {
        title: 'MEETING_NOTIFICATION_STARTING_TITLE',
        message: 'MEETING_NOTIFICATION_STARTING_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.updateAccepted]: {
        title: 'MEETING_NOTIFICATION_UPDATE_ACCEPTED_TITLE',
        message: 'MEETING_NOTIFICATION_UPDATE_ACCEPTED_MESSAGE',
        ...meetingNotificationDefaults
    },
    [MeetingNotificationType.updateDeclined]: {
        title: 'MEETING_NOTIFICATION_UPDATE_DECLINED_TITLE',
        message: 'MEETING_NOTIFICATION_UPDATE_DECLINED_MESSAGE',
        ...meetingNotificationDefaults
    }
};
