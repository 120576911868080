import { AvailabilityQueryParams } from '../interfaces/availability-query-params.interface';

export const MeetingBookingEndpoints = {
    meetings: () => '/mbookings/meetings/',
    allMeetings: () => MeetingBookingEndpoints.meetings() + 'full/',
    meetingDetail: (meetingId: string) => `/mbookings/meeting/${meetingId}/`,
    meetingInvitees: (meetingId: string) => `/mbookings/meeting_invitees/${meetingId}/`,
    acceptMeeting: (meetingId: string) => MeetingBookingEndpoints.userMeetingDetail(meetingId) + 'accept/',
    declineMeeting: (meetingId: string) => MeetingBookingEndpoints.userMeetingDetail(meetingId) + 'reject/',
    availability: () => '/mbookings/slots/',
    groupAvailability: () => '/mbookings/group-slots/',
    available: () => '/mbookings/available/',
    availableTags: () => '/mbookings/tags/',
    locations: (range: AvailabilityQueryParams) => `/mbookings/locations/?from=${range.from}&to=${range.to}/`,
    userMeetingDetail: (meetingId: string) => `/mbookings/user_meetings/${meetingId}/`,
    joinVideoCall: (meetingId: string) => MeetingBookingEndpoints.meetingDetail(meetingId) + 'join/',
    meetingBookingConfig: () => '/mbookings/integration/',
    recurringMeeting: (meetingId) => `/mbookings/recurring/meeting/${meetingId}/`,
    recurringUserMeetingDetail: (meetingId) => `/mbookings/recurring/user_meetings/${meetingId}/`,
    recurringAcceptMeeting: (meetingId) => MeetingBookingEndpoints.recurringUserMeetingDetail(meetingId) + 'accept/',
    recurringRejectMeeting: (meetingId) => MeetingBookingEndpoints.recurringUserMeetingDetail(meetingId) + 'reject/',
    recurringInvitees: (meetingId) => `/mbookings/recurring/meeting_invitees/${meetingId}/`,
    emailMeetings: () => `/mbookings/user_meeting_report/`,
    downloadMeetings: () => `/mbookings/user_meeting_report_dl/`
};
