import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/window.service';
import { TranslateService } from '@ngx-translate/core';
import { App, AppLanguage } from '@shared/api';
import { Settings as LuxonSettings } from 'luxon';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { I18nTokenService } from './i18n-token.service';

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    public languages: AppLanguage[];
    public isRtl$: Observable<boolean>;
    public language$: Observable<string>;

    readonly defaultLocale = 'en';
    readonly rtlLanguages = ['ar'];

    private isRtlSubject = new BehaviorSubject<boolean>(false);
    private languageSubject = new ReplaySubject<string>(1);

    constructor(
        private translateService: TranslateService,
        private i18nTokenService: I18nTokenService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window
    ) {
        this.isRtl$ = this.isRtlSubject.asObservable();
        this.language$ = this.languageSubject.asObservable();
    }

    public get isRtl(): boolean {
        return this.isRtlSubject.value;
    }

    public setLanguage(app: string, locale: string): void {
        this.i18nTokenService.set(app, locale);
        this.translateService.use(locale);

        this.languageSubject.next(locale);

        const isRtl = this.rtlLanguages.includes(locale);
        const direction = isRtl ? 'rtl' : 'ltr';

        this.isRtlSubject.next(isRtl);

        // Set the dir attribute on the html tag
        this.document.documentElement.dir = direction;

        // Set the lan attribute on the html tag
        this.document.documentElement.lang = locale || this.defaultLocale;
    }

    public initialiseAppLanguages(app: App): void {
        // Current language
        const locale: string = this.getLanguageForApp(app);
        this.setLanguage(app.url_name, locale);

        // Translate service
        this.translateService.setDefaultLang(locale);

        // App languages
        this.languages = app.languages;

        // Luxon locale
        LuxonSettings.defaultLocale = this.window.navigator.language;
    }

    private getLanguageForApp(app: App): string {
        if (app.languages.length === 1) {
            return app.languages[0].language_code;
        }

        const storedLanguage = this.i18nTokenService.get(app.url_name);

        if (storedLanguage) {
            return storedLanguage;
        }

        const matchingNavigatorLanguage = app.languages.find((appLanguage) =>
            this.window.navigator.languages.find((navigatorLanguage) =>
                navigatorLanguage.toLowerCase().includes(appLanguage.language_code)
            )
        );

        if (matchingNavigatorLanguage) {
            return matchingNavigatorLanguage.language_code;
        }

        const defaultLanguage = app.languages.find(
            (appLanguage) => appLanguage.language_code === app.language.toString()
        );

        if (defaultLanguage) {
            return defaultLanguage.language_code;
        }

        return app.languages[0].language_code;
    }
}
