import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeetingBookingEndpoints } from '@features/meeting-booking/constants/meeting-booking-endpoints.constant';
import { MeetingCategory } from '@features/meeting-booking/enums/meeting-category.enum';
import { AvailabilityQueryParams } from '@features/meeting-booking/interfaces/availability-query-params.interface';
import { CreateMeetingPayload } from '@features/meeting-booking/interfaces/create-meeting-payload.interface';
import { MeetingsFullPayload } from '@features/meeting-booking/interfaces/meetings-full-payload.interface';
import {
    MeetingAvailabilityResponse,
    MeetingAvailabilitySegment
} from '@features/meeting-booking/store/models/meeting-availability-segment.interface';
import { MeetingBookingConfigModel } from '@features/meeting-booking/store/models/meeting-booking-config.model';
import { MeetingBookingTag } from '@features/meeting-booking/store/models/meeting-booking-tag.model';
import { MeetingLocation } from '@features/meeting-booking/store/models/meeting-location.interface';
import { ExpandedMeeting, Meeting } from '@features/meeting-booking/store/models/meeting.model';
import { VideoCall } from '@features/video-calls/models/video-call.interface';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MeetingBookingApiService {
    constructor(private httpClient: HttpClient) {}

    public getMeetingBookingConfig(appUrl: string): Observable<MeetingBookingConfigModel> {
        return this.httpClient.get<MeetingBookingConfigModel>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetingBookingConfig())
        );
    }

    public getMeetings(appUrl: string): Observable<PaginatedResponse<Meeting>> {
        return this.httpClient.get<PaginatedResponse<Meeting>>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetings())
        );
    }

    public getMeetingsWithRecurring(appUrl: string): Observable<PaginatedResponse<Meeting>> {
        return this.httpClient.get<PaginatedResponse<Meeting>>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetings()),
            {
                params: {
                    recurring: true
                }
            }
        );
    }

    public createMeeting(appUrl: string, payload: CreateMeetingPayload): Observable<Meeting> {
        return this.httpClient.post<Meeting>(ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetings()), payload);
    }

    public updateMeeting(
        appUrl: string,
        meetingId: string,
        payload: Partial<CreateMeetingPayload>
    ): Observable<Meeting> {
        return this.httpClient.patch<Meeting>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetingDetail(meetingId)),
            payload
        );
    }

    public updateRecurringMeeting(
        appUrl: string,
        recurrenceParentId: string,
        originalStart: string,
        payload: Partial<CreateMeetingPayload>
    ): Observable<Meeting> {
        return this.httpClient.patch<Meeting>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.recurringMeeting(recurrenceParentId)),
            { ...payload, orig_start: originalStart }
        );
    }

    public updateUserMeeting(appUrl: string, meetingId: string, payload: Partial<Meeting>): Observable<unknown> {
        return this.httpClient.patch<Meeting>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.userMeetingDetail(meetingId)),
            payload
        );
    }

    public updateRecurringUserMeeting(
        appUrl: string,
        recurrenceParentId: string,
        originalStart: string,
        payload: Partial<Meeting>
    ): Observable<unknown> {
        return this.httpClient.patch<Meeting>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.recurringUserMeetingDetail(recurrenceParentId)),
            { ...payload, orig_start: originalStart }
        );
    }

    public getMeeting(appUrl: string, meetingId: string): Observable<ExpandedMeeting> {
        return this.httpClient.get<ExpandedMeeting>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetingDetail(meetingId))
        );
    }

    public addMeetingInvitees(appUrl: string, meetingId: string, invitees: Array<string>): Observable<unknown> {
        return this.httpClient.post<unknown>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetingInvitees(meetingId)),
            invitees
        );
    }

    public addRecurringMeetingInvitees(
        appUrl: string,
        recurrenceParentId: string,
        invitees: Array<string>,
        start: string
    ): Observable<unknown> {
        return this.httpClient.post<unknown>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.recurringInvitees(recurrenceParentId)),
            {
                invited_users: invitees,
                orig_start: start
            }
        );
    }

    public getPaginatedMeetings(
        appUrl: string,
        status: MeetingCategory,
        query: PaginationState
    ): Observable<PaginatedResponse<ExpandedMeeting>> {
        switch (status) {
            case MeetingCategory.Sent:
            case MeetingCategory.Received:
                return this.getPaginatedMeetingsWithRecurring(appUrl, status, query);
            case MeetingCategory.Upcoming:
            case MeetingCategory.Completed:
            default:
                return this.getPaginatedMeetingsWithRecurring(appUrl, status, { ...query, recurring: true });
        }
    }

    public getPaginatedMeetingsWithRecurring(
        appUrl: string,
        status: MeetingCategory,
        query: PaginationState & { recurring?: boolean }
    ): Observable<PaginatedResponse<ExpandedMeeting>> {
        return this.httpClient
            .get<ExpandedMeeting[]>(ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetings()), {
                params: {
                    status,
                    ...query
                },
                observe: 'response'
            })
            .pipe(
                map((val) => ({
                    count: parseInt(val.headers.get('page-total') ?? '0', 10),
                    results: val.body?.map((meeting) => ({
                        ...meeting,
                        id: meeting.id ?? `${meeting.recurrence_parent_id}~${meeting.orig_start}`
                    }))
                }))
            );
    }

    public getAllMeetings(appUrl: string, query: MeetingsFullPayload): Observable<ExpandedMeeting[]> {
        return this.httpClient.get<ExpandedMeeting[]>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.allMeetings()),
            {
                params: {
                    ...query
                }
            }
        );
    }

    public acceptMeeting(appUrl: string, meetingId: string): Observable<unknown> {
        return this.httpClient.post(ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.acceptMeeting(meetingId)), {});
    }

    public acceptRecurringMeeting(
        appUrl: string,
        recurrenceParentId: string,
        originalStart: string
    ): Observable<unknown> {
        return this.httpClient.post(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.recurringAcceptMeeting(recurrenceParentId)),
            {
                orig_start: originalStart
            }
        );
    }

    public declineMeeting(appUrl: string, meetingId: string): Observable<unknown> {
        return this.httpClient.post(ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.declineMeeting(meetingId)), {});
    }

    public declineRecurringMeeting(
        appUrl: string,
        recurrenceParentId: string,
        originalStart: string
    ): Observable<unknown> {
        return this.httpClient.post(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.recurringRejectMeeting(recurrenceParentId)),
            {
                orig_start: originalStart
            }
        );
    }

    public cancelMeeting(appUrl: string, meetingId: string): Observable<unknown> {
        return this.httpClient.delete(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.meetingDetail(meetingId)),
            {}
        );
    }

    public cancelRecurringMeeting(
        appUrl: string,
        recurrenceParentId: string,
        originalStart: string
    ): Observable<unknown> {
        return this.httpClient.delete(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.recurringMeeting(recurrenceParentId)),
            {
                body: {
                    orig_start: originalStart
                }
            }
        );
    }

    public getMyAvailability(
        appUrl: string,
        params: AvailabilityQueryParams
    ): Observable<MeetingAvailabilityResponse[]> {
        return this.httpClient.get<MeetingAvailabilityResponse[]>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.availability()),
            {
                params: { ...params }
            }
        );
    }

    public getUserAvailability(
        appUrl: string,
        personId: string | number,
        params: AvailabilityQueryParams
    ): Observable<MeetingAvailabilityResponse[]> {
        return this.httpClient.get<MeetingAvailabilityResponse[]>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.availability()),
            {
                params: {
                    person_id: personId,
                    from: params.from,
                    to: params.to
                }
            }
        );
    }

    public getGroupAvailability(
        appUrl: string,
        personIds: string[] | number[],
        params: AvailabilityQueryParams
    ): Observable<MeetingAvailabilityResponse[]> {
        return this.httpClient.post<MeetingAvailabilityResponse[]>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.groupAvailability()),
            personIds,
            {
                params: {
                    ...params
                }
            }
        );
    }

    public getAvailableTags(appUrl: string): Observable<MeetingBookingTag[]> {
        return this.httpClient.get<MeetingBookingTag[]>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.availableTags())
        );
    }

    public getAvailableLocations(appUrl: string, range: AvailabilityQueryParams): Observable<MeetingLocation[]> {
        return this.httpClient.get<MeetingLocation[]>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.locations(range))
        );
    }

    /**
     * @param segments A list of UTC time segments that represent time slots where the user is NOT available
     * @param params The range of time that we want to update the availability for. Should be in UTC.
     */
    public updateMyAvailability(
        appUrl: string,
        segments: Array<MeetingAvailabilitySegment>,
        params: AvailabilityQueryParams
    ): Observable<MeetingAvailabilitySegment[]> {
        return this.httpClient.put<MeetingAvailabilitySegment[]>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.availability()),
            segments,
            {
                params: {
                    from: params.from,
                    to: params.to
                }
            }
        );
    }

    public joinVideoCall(appUrl: string, meetingId: string): Observable<VideoCall> {
        return this.httpClient.post<VideoCall>(
            ApiSettings.fullUrl(appUrl, MeetingBookingEndpoints.joinVideoCall(meetingId)),
            {}
        );
    }

    public downloadMeetings(appName: string, status: MeetingCategory): Observable<Blob> {
        const url = ApiSettings.fullUrl(appName, MeetingBookingEndpoints.downloadMeetings());
        return this.httpClient.get<Blob>(url, {
            params: { status },
            responseType: 'blob' as 'json'
        });
    }
}
